import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { FaSignInAlt as RegisterIcon } from '@react-icons/all-files/fa/FaSignInAlt';
import TextField from 'components/ReduxForm/TextField';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import emailValidation from './emailValidation';
import passwordValidation from './passwordValidation';

const RecoveryPasswordForm = (props: { token: string; onSubmit: (values: any) => any }) => {
  const { onSubmit, token } = props;

  return (
    <Form
      initialValues={{ token }}
      onSubmit={(values) =>
        onSubmit(values).then(
          () => undefined,
          (err: any) => err
        )
      }
      validate={token ? passwordValidation : emailValidation}
      render={({ handleSubmit, submitError, validating, submitting, pristine, invalid }) => (
        <form method="post" onSubmit={handleSubmit}>
          <Grid spacing={1} container>
            <Grid item xs={12}>
              {!token && (
                <Field
                  name="email"
                  type="text"
                  fullWidth
                  component={TextField}
                  label={<FormattedMessage id="email" defaultMessage="Email" />}
                />
              )}
              {!!token && (
                <>
                  <Field
                    name="password"
                    type="password"
                    fullWidth
                    component={TextField}
                    label={
                      <FormattedMessage id="typeNewPassword" defaultMessage="Type new password" />
                    }
                  />
                  <Field name="token" type="hidden" component={TextField} />
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {submitError && (
                <Typography
                  sx={{
                    marginTop: 10,
                  }}
                  color="error"
                >
                  {submitError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={validating || submitting || pristine || invalid}
                sx={{
                  marginBottom: 10,
                  marginTop: 10,
                }}
                fullWidth
                variant="outlined"
                color="secondary"
                type="submit"
              >
                <RegisterIcon style={{ marginRight: 10 }} />
                <FormattedMessage id="Send" defaultMessage="Send" />
              </Button>
              {(validating || submitting) && <LinearProgress color="secondary" />}
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default RecoveryPasswordForm;
