/* eslint-disable react/no-unstable-nested-components */
import { makeStyles } from '@mui/styles';
import LoginForm from 'components/LoginForm/LoginForm';
import RecoveryPasswordForm from 'components/RecoveryPasswordForm/RecoveryPasswordForm';
import cookie from 'cookies-js';
import qs from 'qs';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resetPwdLong, sendResetPwd } from 'redux/modules/account/authManagement';
import { load, login as loginAction, logout as logoutAction } from 'redux/modules/auth';
import { getLink } from 'utils/getLink';
import LoginBg from 'components/Img/loginbg.jpg';

import { Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { useNotifier } from 'redux/modules/notifier';

const useStyles = makeStyles(
  {
    container: {
      textAlign: 'center',
      marginTop: -64,
      height: '100vh',
      backgroundImage: `url(${LoginBg})`,
      backgroundReapeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    card: {
      maxWidth: 345,
    },
    typoColor: {
      color: '#fff',
    },
    buttonRegister: {
      marginTop: 10,
    },
    facebook: {
      background: '#4267b2',
      border: '1px solid #4267b2',
      color: '#fff',
      '&:hover': {
        color: '#4267b2',
      },
    },
    loginTitle: {
      fontFamily: 'wm-marketing-display-font,Georgia,Cambria,"Times New Roman",Times,serif',
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '36px',
      transform: 'translateY(-2px)',
    },
    buttonFacebook: {
      marginBottom: 10,
      backgroundColor: '#3b5998',
      color: '#fff',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#1d315b',
      },
      '& svg': {
        width: 21,
        height: 21,
        marginRight: 4,
        position: 'relative',
        top: -1,
      },
    },
    buttonTwitter: {
      backgroundColor: '#55acee',
      color: '#fff',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#006daa',
      },
      '& svg': {
        width: 21,
        height: 21,
        marginRight: 4,
        position: 'relative',
        top: -1,
      },
    },
  },
  { name: 'Login' }
);

const Login = () => {
  const dispatch = useDispatch();
  const { closeSnackbar, enqueueSnackbar } = useNotifier();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    recovery: false,
  });
  const classes = useStyles();

  const intl = useIntl();

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'auth' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const user = useSelector((s) => s.auth.user);
  const {
    verifySignUpResult,
    verifySignUpResultError,
    verifyChangesResult,
    verifyChangesResultError,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'authManagement' does not exist on type '... Remove this comment to see the full error message
  } = useSelector((s) => s.authManagement);

  const successLogin = () => {
    enqueueSnackbar({
      // eslint-disable-next-line quotes
      message: "You're logged !",
      options: {
        severity: 'success',
        autoHideDuration: 10000,
        action: (key: any) => <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>,
      },
    });
  };

  useEffect(() => {
    if (location.search.indexOf('redirect') !== -1) {
      cookie.set('redirect', location.search);
    }

    if (user && cookie.get('redirect') && params.type !== 'reset') {
      const cquery = cookie.get('redirect');
      try {
        const query = qs.parse(cquery, { ignoreQueryPrefix: true });
        successLogin();
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        navigate(query.redirect || '/login-success');
        // navigate('/admin/dashboard');
      } catch (e) {
        console.error(e);
      }
    }

    if (!verifySignUpResultError && verifySignUpResult) {
      enqueueSnackbar({
        message: 'Your email has been verified. We can now protect your account and you can login.',
        options: {
          severity: 'success',
          autoHideDuration: 10000,
          action: (key: any) => <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>,
        },
      });
    } else if (verifySignUpResultError) {
      enqueueSnackbar({
        message: 'Sorry, but we could not verify your email.',
        options: {
          severity: 'error',
          autoHideDuration: 10000,
          action: (key: any) => <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>,
        },
      });
    }

    if (!verifyChangesResultError && verifyChangesResult) {
      load();
      dispatch(
        enqueueSnackbar({
          message: 'You have approved the changes to your account.',
          options: {
            severity: 'success',
            autoHideDuration: 10000,
            action: (key: any) => <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>,
          },
        })
      );
    } else if (verifyChangesResultError) {
      enqueueSnackbar({
        message: `Sorry, but we could not approved the changes to your account:
        ${verifyChangesResultError.message || ''}`,
        options: {
          severity: 'error',
          autoHideDuration: 10000,
          action: (key: any) => <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>,
        },
      });
    }
  }, []);

  const login = async (data: any) => {
    const result = await dispatch(loginAction('local', data));
    successLogin();
    return result;
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  const recovery = async (data: any) => {
    const result = data.token
      ? await dispatch(resetPwdLong(data.token, data.password))
      : await dispatch(sendResetPwd(data.email));
    if (data.token) {
      enqueueSnackbar({
        message: 'Your password is restored',
        options: {
          severity: 'success',
          autoHideDuration: 10000,
          action: (key: any) => <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>,
        },
      });
      navigate('/login');
    } else {
      enqueueSnackbar({
        message: 'We send you a email with instructions',
        options: {
          severity: 'success',
          autoHideDuration: 10000,
          action: (key: any) => <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>,
        },
      });
    }

    return result;
  };

  let title = intl.formatMessage({ id: 'login', defaultMessage: 'Login' });

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isLoadingAuth = query.connect;
  // && location.hash.indexOf('access_token=') !== -1;
  if (isLoadingAuth) {
    title = 'Loading session';
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={`${classes.container}`}>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <link rel="canonical" href={getLink('/login', false)} />
      </Helmet>
      {(state.recovery || params.type === 'reset') && (
        <Grid item>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.loginTitle} variant="h1">
                <FormattedMessage id="recoveryPassword" defaultMessage="Recovery password" />
              </Typography>
              <RecoveryPasswordForm
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | false | undefined' is not assignabl... Remove this comment to see the full error message
                token={params.type === 'reset' && params['*']}
                onSubmit={recovery}
              />
              <br />
              {params.type !== 'reset' && (
                <Button
                  onClick={() => {
                    setState({ recovery: false });
                  }}
                >
                  Login
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
      )}
      {!state.recovery && params.type !== 'reset' && (
        <Grid item>
          <Card className={classes.card}>
            <CardContent>
              {!user && !isLoadingAuth && (
                <Typography className={classes.loginTitle} variant="h1">
                  <FormattedMessage id="login" defaultMessage="Login" />
                </Typography>
              )}
              {!user && !isLoadingAuth && (
                <div>
                  <LoginForm onSubmit={login} />
                  {/* <Button
                    component={Link}
                    to={`/register${location.search}`}
                    className={classes.buttonRegister}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                  >
                    <FormattedMessage id="createAccount" defaultMessage="Create account" />
                  </Button> */}
                </div>
              )}
              {isLoadingAuth && (
                <>
                  <Typography paragraph>Connecting with your account...</Typography>
                  <div>
                    <CircularProgress className={classes.progress} />
                  </div>
                </>
              )}
              {user && (
                <>
                  <br />
                  <Typography paragraph>
                    You are currently logged in as {user.name || user.email}.
                  </Typography>

                  <div>
                    <Button variant="contained" color="secondary" onClick={logout}>
                      <FormattedMessage id="signOut" defaultMessage="Sign out" />
                    </Button>
                  </div>
                </>
              )}
              <br />
              <Button
                onClick={() => {
                  setState({ recovery: true });
                }}
              >
                Reset password
              </Button>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default Login;
