import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { TextField } from 'components/MaterialFinalFormFields';
import { FaSignInAlt as RegisterIcon } from '@react-icons/all-files/fa/FaSignInAlt';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { Grid, Button, Typography, LinearProgress } from '@mui/material';
import loginValidation from './loginValidation';

const useStyles = makeStyles(
  {
    buttonLogin: {
      marginBottom: 10,
      marginTop: 10,
    },
    containerError: {
      marginTop: 10,
    },
  },
  { name: 'LoginForm' }
);

const LoginForm = (props: any) => {
  const { onSubmit } = props;
  const classes = useStyles();

  return (
    <Form
      onSubmit={(values) =>
        onSubmit(values).then(
          () => undefined,
          (err: any) => err
        )
      }
      validate={loginValidation}
      render={({ handleSubmit, submitError, submitting }) => (
        <form method="post" onSubmit={handleSubmit}>
          <Grid spacing={1} container>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="username"
                type="text"
                fullWidth
                label={<FormattedMessage id="username" defaultMessage="Username / Email" />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="password"
                type="password"
                fullWidth
                label={<FormattedMessage id="password" defaultMessage="Password" />}
              />
              {submitError && (
                <Typography className={classes.containerError} color="error">
                  {submitError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {submitting && <LinearProgress color="secondary" />}
              <Button
                className={classes.buttonLogin}
                fullWidth
                variant="outlined"
                color="secondary"
                type="submit"
              >
                <RegisterIcon style={{ marginRight: 10 }} />
                <FormattedMessage id="login" defaultMessage="Login" />
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
