import { TextField } from '@mui/material';

export default ({
  input: { name, onChange, value, ...restInput } = {},
  inputInput,
  meta = {},
  helperText,
  onChange: onChange2,
  ...rest
}: any) => (
  <TextField
    key={name}
    {...rest}
    name={name}
    helperText={meta.touched ? meta.error : helperText}
    error={meta.error && meta.touched}
    InputProps={{ ...inputInput, ...restInput }}
    onChange={(...args) => {
      onChange(...args);
      if (onChange2) {
        onChange2(...args);
      }
    }}
    value={value}
  />
);
